import {useState} from "react";
import {Helmet} from 'react-helmet-async';
// @mui
import {
    Box,
    Container,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import {
    CalendarTodayOutlined,
    FiberManualRecord,
    LocalOfferOutlined,
    LocationOnOutlined,
    SendOutlined,
    TranslateOutlined,
    ViewTimelineOutlined
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
    >
        {value === index && (<Box sx={{p: 3}}>
            <Typography>{children}</Typography>
        </Box>)}
    </div>);
}

function OverviewTabPanel() {
    return (<Box>
        <Typography variant="h3" paragraph>
            Introduction
        </Typography>
        <Typography align={"justify"}>
            The Artificial Intelligence (AI) for Professionals course offers an exciting opportunity to delve into
            advanced applications of machine learning and deep learning. Designed to equip participants with the
            skills needed to thrive in AI and Data Science (DS), this program empowers professionals to transform
            data into actionable insights, boost productivity with AI-driven tools, and foster innovation within
            their organizations. <br/>

            Through hands-on training and conceptual frameworks, participants will master advanced techniques for
            analyzing complex datasets and improving productivity in daily tasks, exploring and building AI-driven
            solutions, and implementing strategies that enhance decision-making and performance.
        </Typography>
        <Typography variant="h3" paragraph sx={{pt: 5}}>
            Target Audience
        </Typography>
        <Typography align={"justify"}>
            This program is designed for individuals looking to gain or deepen their expertise in AI, regardless of
            academic or professional background. <br/>

            While familiarity with Python and Jupyter Notebooks is helpful, beginners are welcome, with a preparatory
            session providing an introduction to Python programming and essential tools. <br/>

            Participants are encouraged to apply learned techniques to real-world challenges in their fields, bringing
            relevant data or specific tasks from their daily work. Whether addressing organisation-specific problems or
            advancing personal goals, this program provides the skills needed to drive innovation, boost productivity
            and make data-informed decisions in any domain.
        </Typography>
        <Typography variant="h3" paragraph sx={{pt: 5}}>
            Learning Outcomes
        </Typography>
        <Typography align={"justify"}>
            This course aims to provide participants with a solid foundation in AI and DS principles, emphasizing
            proficiency in data manipulation, Machine Learning (ML), Deep Learning (DL) and Natural Language
            Processing (NLP) techniques. Through a combination of hands-on practice and theoretical knowledge,
            participants will learn to analyse complex datasets, effectively communicate data-driven insights and
            use advanced algorithms and tools to boost productivity. <br/>


            This course will address the following topics:
        </Typography>
        <List sx={{pl: 2}}>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="Introduction to AI and DS, ML paradigms, AI tools, real-world applications; ethics in AI"/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="ML basics: Supervised and unsupervised ML pipelines, key algorithms, model evaluation; model selection and optimisation"/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="Deep Learning: Neural Networks: algorithms and tools; architectures for different data; tools and platforms for DL"/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="NLP and large language models (LLMs); transformers; prompt engineering; multimodal foundation models;"/>
            </ListItem>
        </List>
    </Box>)
}

function ProgrammeTabPanel() {

    return (<Box>
        <Typography variant={"h3"} paragraph>
            Programme
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 1: Introduction to Artificial Intelligence and Data Science (Weeks 1 and 2)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module provides a comprehensive overview of the fundamentals of AI and DS. Participants will explore
            key ML paradigms, real-world applications of AI, and the ethical considerations surrounding its use. The
            practical sessions will cover key Python programming basics needed for the remaining of the course. <br/>

            The module also emphasizes the critical role of data in AI, focusing on techniques for data loading,
            selection, and preprocessing. Participants will gain hands-on experience with Python libraries for data
            sciences: manipulation, visualization, and basic statistical analysis, building a strong foundation for
            advanced topics.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 2: Machine Learning Basics (Weeks 3 and 4)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module introduces the essential concepts and workflows of ML, covering both unsupervised and supervised
            machine learning approaches. Participants will learn how to uncover patterns in data, reduce complexity for
            better visualisation, and build predictive models to solve real-world problems. <br/>

            The module also emphasizes best practices for model evaluation, including error estimation and validation
            techniques, ensuring participants can assess the performance of their solutions effectively. Hands-on
            sessions will provide experience with Python tools for implementing and evaluating ML workflows.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 3: Advanced Machine Learning and Deep Learning (Weeks 5 and 6)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module explores advanced techniques in Machine and Deep Learning, focusing on neural networks and their
            applications. Participants will delve into DL concepts, including feedforward and convolutional neural
            networks, gaining practical experience with Python frameworks such as TensorFlow/ Keras. <br/>

            In addition, the module covers key topics like model ensembles, feature selection, hyperparameter tuning,
            and techniques for handling imbalanced datasets. Participants will also learn methods for comparing and
            optimizing ML/ DL models for specific real-world datasets, with hands-on exercises using Python libraries to
            apply these strategies effectively.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 4: Natural Language Processing and Large Language Models (Weeks 7 and 8)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module delves into Natural Language Processing, covering text processing fundamentals and advancements
            in Large Language Models (LLMs). Participants will explore traditional and advanced text representation,
            including embeddings and pre-trained models, along with key architectures like recurrent NNs, LSTMs,
            attention mechanisms, and transformers. <br/>

            Hands-on exercises offer practical experience in implementing and fine-tuning these models. <br/>

            Additionally, the module covers the most recent LLMs, and their applications, addressing basic prompt
            engineering techniques, equipping participants with the skills to effectively utilize chatbots and LLMs, as
            well as the core APIs for generating and refining responses from LLMs in a productive way for more complex
            tasks.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 5: Artificial Intelligence Tools for Productivity (Weeks 9 and 10)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module further explores the use of LLMs and chat-based applications to enhance productivity.
            Participants will learn advanced prompt engineering techniques to effectively interact with LLMs for various
            tasks. Hands-on exercises will involve using APIs to integrate LLMs into practical applications. <br/>

            The module also covers how LLMs can assist in writing simple programs and scripts, as well as supporting
            data analysis tasks. Participants will gain experience using LLMs and prompt engineering (PE) to automate
            programming and streamline data analysis processes, improving efficiency and output.
        </Typography>
    </Box>)
}

function TeamTabPanel() {

    return (<Box>
        <Typography variant={"h3"} paragraph>
            Team
        </Typography>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <img src={"/assets/jcorreia.png"} alt={"João Correia"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    João Correia <br/>
                    Training Coordinator | Head of Chemoinfomatics Development @omniumai | Invited Teaching Assistant
                    @CatolicaBraga
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/mrocha.jpg"} alt={"Miguel Rocha"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    Miguel Rocha <br/>
                    Scientific Advisor | CSO @omniumai | Associate Professor with Habilitation @Uminho
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/capela.jpeg"} alt={"João Capela"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    João Capela <br/>
                    PhD Student | Invited Teaching Assistant @UMinho
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/msampaio.jpg"} alt={"Marta Sampaio"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    Marta Sampaio <br/>
                    PhD | Head of Bioinformatics Development @omniumai
                </Typography>
            </Grid>
        </Grid>

    </Box>)
}


function TabsBox({value, width, handleChange, a11yProps}) {

    return (<Box sx={{maxWidth: width}}>
        <Tabs value={value}
              variant="scrollable"
              scrollButtons={true}
              onChange={handleChange}
              aria-label="basic tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Programme" {...a11yProps(1)} />
            <Tab label="Team" {...a11yProps(2)} />
        </Tabs>
    </Box>)
}

// ----------------------------------------------------------------------
export default function AIFPPage() {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (<>
        <Helmet>
            <title> OmniumAI </title>
        </Helmet>
        <Grid container
              sx={{
                  minHeight: '60vh',
                  minWidth: '100vw',
                  pl: {xs: 1, sm: 2, md: 5},
                  backgroundColor: "rgba(192,201,200,0.3)"
              }}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={3}>
            <Grid item xs={12} md={6}>
                <Typography variant="h2" sx={{pt: 5}}>
                    AI for Professionals
                </Typography>
                {/*<Typography variant="h3" sx={{pt: 5}} paragraph>*/}
                {/*    Artificial Intelligence for the Life Sciences*/}
                {/*</Typography>*/}
                {/*<Typography*/}
                {/*    sx={{my: 5}}*/}
                {/*    variant="h4"*/}
                {/*    paragraph>*/}
                {/*    All you need to know to get started with AI in the life sciences.*/}
                {/*</Typography>*/}
            </Grid>
            <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={0} md={6}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} sx={{pb: 2}}>
                    <img src={'/assets/ai-pr.png'} alt={'ails'} height={'300px'}/>
                </Stack>
            </Grid>
        </Grid>
        <Container maxWidth="xl"
                   sx={{px: {xs: 1, md: 3}, pt: {xs: 5}, pb: 3}}>
            <Grid container
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  spacing={2}>
                <Grid item order={{xs: 2, md: 1}} xs={12} md={10}>
                    {matchesLG ? <TabsBox value={value}
                                          width={"800px"}
                                          handleChange={handleChange}
                                          a11yProps={a11yProps}/> : matchesMD ? <TabsBox value={value}
                                                                                         width={"550px"}
                                                                                         handleChange={handleChange}
                                                                                         a11yProps={a11yProps}/> : matchesSM ?
                        <TabsBox value={value}
                                 width={"400px"}
                                 handleChange={handleChange}
                                 a11yProps={a11yProps}/> : <TabsBox value={value}
                                                                    width={"350px"}
                                                                    handleChange={handleChange}
                                                                    a11yProps={a11yProps}/>}
                    <TabPanel value={value} index={0}>
                        <OverviewTabPanel/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ProgrammeTabPanel/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TeamTabPanel/>
                    </TabPanel>
                </Grid>
                <Grid item order={{xs: 1, md: 2}} xs={12} md={2}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CalendarTodayOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    January 2025
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <ViewTimelineOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    40h - 10 weeks
                                </Typography>
                                <Typography variant={"h6"}>
                                    (5 modules, 8h each)
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <LocationOnOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    Online
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <LocalOfferOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    From 600€
                                </Typography>
                                <Typography variant={"h6"}>
                                    (Early Bird)
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <TranslateOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    English
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <SendOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    <Link href={"https://form.jotform.com/243512777201048"}>
                                        Pre-Register
                                    </Link>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    </>)
}