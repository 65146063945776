import {useState} from "react";
import {Helmet} from 'react-helmet-async';
// @mui
import {
    Box,
    Container,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import {
    CalendarTodayOutlined,
    FiberManualRecord,
    LocalOfferOutlined,
    LocationOnOutlined,
    SendOutlined,
    TranslateOutlined,
    ViewTimelineOutlined
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
    >
        {value === index && (<Box sx={{p: 3}}>
            <Typography>{children}</Typography>
        </Box>)}
    </div>);
}

function OverviewTabPanel() {
    return (<Box>
        <Typography variant="h3" paragraph>
            Introduction
        </Typography>
        <Typography align={"justify"}>
            The Artificial Intelligence (AI) in Biology and Biomedicine course offers an exciting opportunity to explore
            cutting-edge applications of machine learning (ML) and deep learning (DL) in biological and biomedical
            domains. Designed to equip participants with the skills required to excel in AI and Data Science (DS), this
            program empowers professionals to transform complex biological data into actionable insights and advance
            innovations in research and industry. <br/>


            Through hands-on training and comprehensive frameworks, participants will master advanced techniques for
            analyzing biological sequences, processing omics datasets, and leveraging AI to solve challenges in life
            sciences. The program emphasizes practical applications, enabling participants to explore ML and DL
            pipelines, utilize bioinformatics tools, and implement strategies that enhance research outcomes and
            decision-making in healthcare, biotechnology, and related fields.
        </Typography>
        <Typography variant="h3" paragraph sx={{pt: 5}}>
            Target Audience
        </Typography>
        <Typography align={"justify"}>
            This program is designed for professionals and researchers seeking to gain or enhance expertise in applying
            AI to biological and biomedical data, regardless of their academic or professional background. While
            familiarity with Python and Jupyter Notebooks is helpful, beginners are welcome, with a preparatory session
            introducing Python programming and essential computational tools.<br/>

            Participants are encouraged to apply the techniques learned to real-world challenges in life sciences,
            healthcare, or related fields, bringing relevant datasets or tasks from their work. Whether advancing
            research goals, addressing organizational challenges, or exploring new applications in biotechnology or
            bioinformatics, this program provides the skills needed to analyze complex data, foster innovation, and make
            data-driven decisions in the rapidly evolving field of biological sciences.
        </Typography>
        <Typography variant="h3" paragraph sx={{pt: 5}}>
            Learning Outcomes
        </Typography>
        <Typography align={"justify"}>
            This course aims to provide participants with a strong foundation in AI and Data Science (DS) principles,
            with a specialized focus on Machine Learning (ML) and Deep Learning (DL) techniques for biological and
            biomedical data. Through a combination of hands-on practice and theoretical knowledge, participants will
            learn to analyze complex biological datasets, effectively communicate data-driven insights, and apply
            advanced ML and DL algorithms to solve challenges in life sciences.<br/>

            This course will address the following topics:
        </Typography>
        <List sx={{pl: 2}}>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="Introduction to AI and DS: ML paradigms, AI tools, real-world applications, ethics in AI"/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="ML basics: Supervised and unsupervised ML pipelines, key algorithms, model evaluation, model selection, and optimization"/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="Deep Learning: Neural networks, architectures for sequence and omics data, tools and platforms for DL"/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="ML for Biological Sequences: Computational representations of DNA, RNA, and proteins; bioinformatics tools for sequence annotation; DL for protein structure prediction; embeddings and foundation models for sequences "/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="ML for Omics Data: Structure and analysis of omics data (genomics, transcriptomics, proteomics, metabolomics); feature selection and differential expression analysis; unsupervised learning for pattern discovery; classification techniques for phenotype prediction"/>
            </ListItem>
        </List>
    </Box>)
}

function ProgrammeTabPanel() {

    return (<Box>
        <Typography variant={"h3"} paragraph>
            Programme
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 1: Introduction to Artificial Intelligence and Data Science (Weeks 1 and 2)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module provides a comprehensive overview of the fundamentals of AI and DS. Participants will explore
            key ML paradigms, real-world applications of AI, and the ethical considerations surrounding its use. The
            practical sessions will cover key Python programming basics needed for the remaining of the course.<br/>

            The module also emphasizes the critical role of data in AI, focusing on techniques for data loading,
            selection, and preprocessing. Participants will gain hands-on experience with Python libraries for data
            sciences: manipulation, visualization, and basic statistical analysis, building a strong foundation for
            advanced topics.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 2: Machine Learning Basics (Weeks 3 and 4)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module introduces the essential concepts and workflows of ML, covering both unsupervised and supervised
            machine learning approaches. Participants will learn how to uncover patterns in data, reduce complexity for
            better visualisation, and build predictive models to solve real-world problems.<br/>

            The module also emphasizes best practices for model evaluation, including error estimation and validation
            techniques, ensuring participants can assess the performance of their solutions effectively. Hands-on
            sessions will provide experience with Python tools for implementing and evaluating ML workflows.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 3: Advanced Machine Learning and Deep Learning (Weeks 5 and 6)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module explores advanced techniques in Machine and Deep Learning, focusing on neural networks and their
            applications. Participants will delve into DL concepts, including feedforward and convolutional neural
            networks, gaining practical experience with Python frameworks such as TensorFlow/ Keras.<br/>

            In addition, the module covers key topics like model ensembles, feature selection, hyperparameter tuning,
            and techniques for handling imbalanced datasets. Participants will also learn methods for comparing and
            optimizing ML/ DL models for specific real-world datasets, with hands-on exercises using Python libraries to
            apply these strategies effectively.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 4: Machine Learning for Biological Sequences (Weeks 7 and 8)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module covers ML applications for analyzing biological sequences, focusing on DNA, RNA and proteins.
            Participants will explore repositories and learn how to use bioinformatics tools for sequence annotation.
            The module introduces computational representations and covers both unsupervised and supervised ML pipelines
            over biological sequences.<br/>

            The module further explores DL techniques for protein sequences, including the use of recurrent and
            convolutional neural networks, embeddings, transformers and recent foundation models. Participants will also
            learn about protein structure prediction with DL methods like AlphaFold. Practical exercises will involve
            using tools to work with protein and DNA/RNA sequences, applying ML and DL models.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 5: Machine Learning for Omics Data (Weeks 9 and 10)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module focuses on the application of ML to omics data, including genomics, transcriptomics, proteomics
            and metabolomics. Participants will learn the structure of omics data and its associated metadata, as well
            as techniques for feature selection and statistical analysis, such as identifying patterns and differential
            expression.<br/>

            The module will also introduce the challenges of working with single-cell RNA data and provide hands-on
            experience in gene expression analysis pipelines. Participants will explore unsupervised learning approaches
            for analysing omics data and gain an understanding of classification techniques for predicting phenotypes
            based on omics datasets.
        </Typography>
    </Box>)
}

function TeamTabPanel() {

    return (<Box>
        <Typography variant={"h3"} paragraph>
            Team
        </Typography>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <img src={"/assets/jcorreia.png"} alt={"João Correia"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    João Correia <br/>
                    Training Coordinator | Head of Chemoinfomatics Development @omniumai | Invited Teaching Assistant
                    @CatolicaBraga
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/mrocha.jpg"} alt={"Miguel Rocha"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    Miguel Rocha <br/>
                    Scientific Advisor | CSO @omniumai | Associate Professor with Habilitation @Uminho
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/capela.jpeg"} alt={"João Capela"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    João Capela <br/>
                    PhD Student | Invited Teaching Assistant @UMinho
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/msampaio.jpg"} alt={"Marta Sampaio"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    Marta Sampaio <br/>
                    PhD | Head of Bioinformatics Development @omniumai
                </Typography>
            </Grid>
        </Grid>

    </Box>)
}


function TabsBox({value, width, handleChange, a11yProps}) {

    return (<Box sx={{maxWidth: width}}>
        <Tabs value={value}
              variant="scrollable"
              scrollButtons={true}
              onChange={handleChange}
              aria-label="basic tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Programme" {...a11yProps(1)} />
            <Tab label="Team" {...a11yProps(2)} />
        </Tabs>
    </Box>)
}

// ----------------------------------------------------------------------
export default function AIBBPage() {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (<>
        <Helmet>
            <title> OmniumAI </title>
        </Helmet>
        <Grid container
              sx={{
                  minHeight: '60vh',
                  minWidth: '100vw',
                  pl: {xs: 1, sm: 2, md: 5},
                  backgroundColor: "rgba(192,201,200,0.3)"
              }}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={3}>
            <Grid item xs={12} md={6}>
                <Typography variant="h2" sx={{pt: 5}}>
                    AI in Biology and Biomedicine
                </Typography>
                {/*<Typography variant="h3" sx={{pt: 5}} paragraph>*/}
                {/*    Artificial Intelligence for the Life Sciences*/}
                {/*</Typography>*/}
                {/*<Typography*/}
                {/*    sx={{my: 5}}*/}
                {/*    variant="h4"*/}
                {/*    paragraph>*/}
                {/*    All you need to know to get started with AI in the life sciences.*/}
                {/*</Typography>*/}
            </Grid>
            <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={0} md={6}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} sx={{pb: 2}}>
                    <img src={'/assets/ai-bb.png'} alt={'ails'} height={'300px'}/>
                </Stack>
            </Grid>
        </Grid>
        <Container maxWidth="xl"
                   sx={{px: {xs: 1, md: 3}, pt: {xs: 5}, pb: 3}}>
            <Grid container
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  spacing={2}>
                <Grid item order={{xs: 2, md: 1}} xs={12} md={10}>
                    {matchesLG ? <TabsBox value={value}
                                          width={"800px"}
                                          handleChange={handleChange}
                                          a11yProps={a11yProps}/> : matchesMD ? <TabsBox value={value}
                                                                                         width={"550px"}
                                                                                         handleChange={handleChange}
                                                                                         a11yProps={a11yProps}/> : matchesSM ?
                        <TabsBox value={value}
                                 width={"400px"}
                                 handleChange={handleChange}
                                 a11yProps={a11yProps}/> : <TabsBox value={value}
                                                                    width={"350px"}
                                                                    handleChange={handleChange}
                                                                    a11yProps={a11yProps}/>}
                    <TabPanel value={value} index={0}>
                        <OverviewTabPanel/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ProgrammeTabPanel/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TeamTabPanel/>
                    </TabPanel>
                </Grid>
                <Grid item order={{xs: 1, md: 2}} xs={12} md={2}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CalendarTodayOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    January 2025
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <ViewTimelineOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    40h - 10 weeks
                                </Typography>
                                <Typography variant={"h6"}>
                                    (5 modules, 8h each)
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <LocationOnOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    Online
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <LocalOfferOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    From 600€
                                </Typography>
                                <Typography variant={"h6"}>
                                    (Early Bird)
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <TranslateOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    English
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <SendOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    <Link href={"https://form.jotform.com/243512777201048"}>
                                        Pre-Register
                                    </Link>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    </>)
}