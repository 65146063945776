import {Helmet} from 'react-helmet-async';
// @mui
import {Grid, Container, Typography, Stack, Button, Box, Avatar, Link} from '@mui/material';
import {
    InsightsOutlined, SchoolOutlined, TuneOutlined
} from "@mui/icons-material";
// components
import UseCaseCard from "../components/use-case-card";
// router
import {Link as RouterLink} from "react-router-dom";

// ----------------------------------------------------------------------
export default function AcademyPage() {
    const backgroundImageStyle = {
        backgroundColor: "#212B36",
        backgroundImage: `url('/assets/training.jpg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
    }

    return (<>
        <Helmet>
            <title> OmniumAI </title>
        </Helmet>
        <Grid container
              sx={{
                  minHeight: '70vh', minWidth: '100vw', pl: {xs: 1, sm: 2, md: 5}, ...backgroundImageStyle
              }}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={3}>
            <Grid item xs={8} md={4}>
                <Typography variant="h2" sx={{pt: 5}} color={"background.default"}>
                    OmniumAI Academy
                </Typography>
                <Typography variant="h4" sx={{pt: 5}} paragraph color={"background.default"}>
                    Your ultimate destination for customized courses in the field of
                    <Typography color={"success.main"} component={'span'}
                                variant={"h4"}>{' '} Life Sciences
                    </Typography> and
                    <Typography color={"error.main"} component={'span'}
                                variant={"h4"}>{' '} AI
                    </Typography>.
                </Typography>
                <Typography variant="h4" sx={{pt: 5}} paragraph color={"background.default"}>
                    Looking for personalized training?
                </Typography>
                <Button
                    variant="contained"
                    component={RouterLink}
                    size={"large"}
                    color={"primary"}
                    to={"mailto:academy@omniumai.com"}>
                    Contact Us
                </Button>
            </Grid>
            <Grid item xs={4} md={8}>
            </Grid>
        </Grid>
        <Container maxWidth="xl"
                   sx={{px: {xs: 1, md: 3}, pb: 3}}>
            <Stack spacing={{xs: 10, md: 15, lg: 20}} sx={{pt: 10}}>
                <div>
                    <Typography variant="h2" align={"center"} paragraph>
                        2025 Offerings
                    </Typography>
                    <Grid container
                          spacing={3}
                          justifyContent="center"
                          alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Box sx={{pl: {xs: 0, md: 5}, pr: {xs: 3, md: 5}}}>
                                <UseCaseCard
                                    useCase={{
                                        cover: `/assets/ai-pr.png`,
                                        title: "AI for Professionals",
                                        subTitle: "",
                                        link: "/academy/aipr"
                                    }}
                                    index={0}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{pl: {xs: 0, md: 5}, pr: {xs: 3, md: 5}}}>
                                <UseCaseCard
                                    useCase={{
                                        cover: `/assets/ai-bb.png`,
                                        title: "AI in Biology and Biomedicine",
                                        subTitle: "",
                                        link: "/academy/aibs"
                                    }}
                                    index={0}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{pl: {xs: 0, md: 5}, pr: {xs: 3, md: 5}}}>
                                <UseCaseCard
                                    useCase={{
                                        cover: `/assets/AI-Chem.png`,
                                        title: "AI in Chemistry and Drug Discovery",
                                        subTitle: "",
                                        link: "/academy/aicm"
                                    }}
                                    index={0}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button component={Link} href={"https://form.jotform.com/243512777201048"}
                                        variant="contained" size="large">Pre-Register</Button>
                            </Stack>
                        </Grid>
                    </Grid>

                </div>
                <div>
                    <Grid container
                          spacing={10}
                          justifyContent="center"
                          alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: 'success.main', height: 100, width: 100
                                    }}
                                >
                                    <SchoolOutlined sx={{fontSize: 50}}/>
                                </Avatar>
                                <Box sx={{textAlign: "center", mt: 1}}>
                                    <Typography variant={"h3"}>
                                        Learn with expert trainers
                                    </Typography>
                                    <Typography sx={{mt: 2}}>
                                        Our expert trainers have years of experience and possess a
                                        deep understanding of the best techniques and tools used in the field of
                                        biological data analysis.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: 'error.main', height: 100, width: 100
                                    }}
                                >
                                    <TuneOutlined sx={{fontSize: 50}}/>
                                </Avatar>
                                <Box sx={{textAlign: "center", mt: 1}}>
                                    <Typography variant={"h3"}>
                                        At your pace
                                    </Typography>
                                    <Typography sx={{mt: 2}}>
                                        We understand that every student has different learning needs, and
                                        therefore,
                                        we personalize our training programs to match their individual goals.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={3}
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: 'primary.main', height: 100, width: 100
                                    }}
                                >
                                    <InsightsOutlined sx={{fontSize: 50}}/>
                                </Avatar>
                                <Box sx={{textAlign: "center", mt: 1}}>
                                    <Typography variant={"h3"}>
                                        For your needs
                                    </Typography>
                                    <Typography sx={{mt: 2}}>
                                        Whether you are looking to enhance your existing knowledge or seeking
                                        to acquire new skills in data analysis, we have got you covered.
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Typography variant="h2" align={"center"} paragraph>
                        Past Courses
                    </Typography>
                    <Grid container
                          spacing={3}
                          justifyContent="center"
                          alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Box sx={{pl: {xs: 0, md: 5}, pr: {xs: 3, md: 5}}}>
                                <UseCaseCard
                                    useCase={{
                                        cover: `/assets/course_ai4ls.jpg`,
                                        title: "AILS - CLOSED",
                                        subTitle: "Artificial Intelligence for the Life Sciences.",
                                        link: "/academy/ai4ls"
                                    }}
                                    index={0}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        </Grid>
                    </Grid>
                </div>
            </Stack>
        </Container>
    </>)
}