import {useState} from "react";
import {Helmet} from 'react-helmet-async';
// @mui
import {
    Box,
    Container,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import {
    CalendarTodayOutlined,
    FiberManualRecord,
    LocalOfferOutlined,
    LocationOnOutlined,
    SendOutlined,
    TranslateOutlined,
    ViewTimelineOutlined
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
    >
        {value === index && (<Box sx={{p: 3}}>
            <Typography>{children}</Typography>
        </Box>)}
    </div>);
}

function OverviewTabPanel() {
    return (<Box>
        <Typography variant="h3" paragraph>
            Introduction
        </Typography>
        <Typography align={"justify"}>
            The AI in Chemistry and Drug Discovery course offers an exciting opportunity to explore the cutting-edge
            applications of AI in cheminformatics, drug discovery, and beyond. This program equips participants with the
            skills to leverage machine learning and deep learning techniques to analyze chemical data, model complex
            systems, and drive innovation in the fields of science and technology.<br/>

            Through hands-on training and in-depth exploration of modern AI frameworks, participants will gain expertise
            in representing and analyzing chemical compounds, building predictive models, and applying advanced neural
            network architectures for real-world challenges. Whether creating interpretable QSAR workflows or designing
            deep learning solutions for compound discovery, this course provides the tools and knowledge necessary to
            harness the power of AI for impactful decision-making and research.
        </Typography>
        <Typography variant="h3" paragraph sx={{pt: 5}}>
            Target Audience
        </Typography>
        <Typography align={"justify"}>
            This program is tailored for professionals and researchers eager to deepen their expertise in AI
            applications for cheminformatics, drug discovery, and related fields. Ideal for those with a background in
            life sciences, chemistry, data science, or related areas, the course also welcomes individuals from diverse
            domains who are keen to explore AI's role in analyzing and modeling complex datasets.<br/>

            While familiarity with Python and Jupyter Notebooks is beneficial, beginners are welcome, as the program
            includes a preparatory session covering Python fundamentals and essential tools. Participants are encouraged
            to apply the techniques learned to challenges specific to their field, such as chemical data analysis,
            predictive modeling, or compound discovery.<br/>

            Whether tackling organizational challenges, advancing research, or pursuing personal projects, this program
            provides the practical skills and insights necessary to drive innovation and enhance decision-making in
            science, technology, and industry.
        </Typography>
        <Typography variant="h3" paragraph sx={{pt: 5}}>
            Learning Outcomes
        </Typography>
        <Typography align={"justify"}>
            This course aims to equip participants with a strong foundation in AI and Data Science (DS) principles, with
            a specific focus on cheminformatics, quantitative structure-activity relationship (QSAR) modeling, and deep
            learning techniques for chemical and drug discovery. Through hands-on practice and theoretical knowledge,
            participants will learn to analyze chemical datasets, build interpretable models, and apply cutting-edge
            neural network architectures to solve domain-specific challenges.<br/>

            This course will address the following topics:
        </Typography>
        <List sx={{pl: 2}}>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="Introduction to AI and DS: Overview of AI applications in science, real-world use cases, and ethical considerations in AI."/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="Cheminformatics and QSAR: Compound representations (1D, 2D, 3D), chemical databases, descriptor calculation, similarity searches, and ML pipelines for QSAR modeling."/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="Deep Learning for Compounds: Neural network architectures for sequential and graph-based data, advanced techniques (e.g., transformers, attention mechanisms), and generative models for compound discovery."/>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    <FiberManualRecord fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText
                    primary="AI Tools and Frameworks: Hands-on experience with RDKit, scikit-learn, TensorFlow/Keras, and PyTorch for chemical data analysis and model building."/>
            </ListItem>
        </List>
    </Box>)
}

function ProgrammeTabPanel() {

    return (<Box>
        <Typography variant={"h3"} paragraph>
            Programme
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 1: Introduction to Artificial Intelligence and Data Science (Weeks 1 and 2)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module provides a comprehensive overview of the fundamentals of AI and DS. Participants will explore
            key ML paradigms, real-world applications of AI, and the ethical considerations surrounding its use. The
            practical sessions will cover key Python programming basics needed for the remaining of the course. <br/>

            The module also emphasizes the critical role of data in AI, focusing on techniques for data loading,
            selection, and preprocessing. Participants will gain hands-on experience with Python libraries for data
            sciences: manipulation, visualization, and basic statistical analysis, building a strong foundation for
            advanced topics.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 2: Machine Learning Basics (Weeks 3 and 4)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module introduces the essential concepts and workflows of ML, covering both unsupervised and supervised
            machine learning approaches. Participants will learn how to uncover patterns in data, reduce complexity for
            better visualisation, and build predictive models to solve real-world problems. <br/>

            The module also emphasizes best practices for model evaluation, including error estimation and validation
            techniques, ensuring participants can assess the performance of their solutions effectively. Hands-on
            sessions will provide experience with Python tools for implementing and evaluating ML workflows.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 3: Advanced Machine Learning and Deep Learning (Weeks 5 and 6)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module explores advanced techniques in Machine and Deep Learning, focusing on neural networks and their
            applications. Participants will delve into DL concepts, including feedforward and convolutional neural
            networks, gaining practical experience with Python frameworks such as TensorFlow/ Keras. <br/>

            In addition, the module covers key topics like model ensembles, feature selection, hyperparameter tuning,
            and techniques for handling imbalanced datasets. Participants will also learn methods for comparing and
            optimizing ML/ DL models for specific real-world datasets, with hands-on exercises using Python libraries to
            apply these strategies effectively.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 4: Cheminformatics and QSAR (Weeks 7 and 8)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module focuses on representing, analyzing, and modeling chemical compounds in cheminformatics.
            Participants will explore various compound representations (1D, 2D, 3D), chemical databases, and file
            formats, as well as methods for computing descriptors, fingerprints, and performing similarity and
            substructure searches. <br/>

            The module also covers QSAR modeling, emphasizing the application of ML techniques to chemical data and the
            importance of model interpretability. Hands-on exercises will involve using Python tools like RDKit for
            compound visualization, dataset creation, and implementing and evaluating QSAR workflows with RDKit and
            scikit-learn.
        </Typography>
        <Typography variant={"h4"} paragraph>
            Module 5: Deep Learning for Compounds and Drug Discovery (Weeks 9 and 10)
        </Typography>
        <Typography align={"justify"} paragraph>
            This module focuses on applying deep learning to chemical compound analysis and drug discovery. Participants
            will explore sequential data processing, embeddings, and neural network architectures (e.g., RNNs, LSTMs,
            attention mechanisms, and transformers) for compound analysis and QSAR/QSPR tasks. <br/>

            Advanced topics include graph neural networks, multimodal approaches combining compound data with proteins
            or omics data, and generative models for compound discovery. Practical sessions using TensorFlow/Keras or
            PyTorch will provide hands-on experience with multi-instance datasets and advanced modeling techniques.
        </Typography>
    </Box>)
}

function TeamTabPanel() {
    return (<Box>
        <Typography variant={"h3"} paragraph>
            Team
        </Typography>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <img src={"/assets/jcorreia.png"} alt={"João Correia"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    João Correia <br/>
                    Training Coordinator | Head of Chemoinfomatics Development @omniumai | Invited Teaching Assistant
                    @CatolicaBraga
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/mrocha.jpg"} alt={"Miguel Rocha"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    Miguel Rocha <br/>
                    Scientific Advisor | CSO @omniumai | Associate Professor with Habilitation @Uminho
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/capela.jpeg"} alt={"João Capela"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    João Capela <br/>
                    PhD Student | Invited Teaching Assistant @UMinho
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={"/assets/msampaio.jpg"} alt={"Marta Sampaio"} style={{height: "200px"}}/>
                <Typography variant={"h5"} paragraph>
                    Marta Sampaio <br/>
                    PhD | Head of Bioinformatics Development @omniumai
                </Typography>
            </Grid>
        </Grid>

    </Box>)
}


function TabsBox({value, width, handleChange, a11yProps}) {

    return (<Box sx={{maxWidth: width}}>
        <Tabs value={value}
              variant="scrollable"
              scrollButtons={true}
              onChange={handleChange}
              aria-label="basic tabs example">
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Programme" {...a11yProps(1)} />
            <Tab label="Team" {...a11yProps(2)} />
        </Tabs>
    </Box>)
}

// ----------------------------------------------------------------------
export default function AICDDPage() {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (<>
        <Helmet>
            <title> OmniumAI </title>
        </Helmet>
        <Grid container
              sx={{
                  minHeight: '60vh',
                  minWidth: '100vw',
                  pl: {xs: 1, sm: 2, md: 5},
                  backgroundColor: "rgba(192,201,200,0.3)"
              }}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={3}>
            <Grid item xs={12} md={6}>
                <Typography variant="h2" sx={{pt: 5}}>
                    AI in Chemistry and Drug Discovery
                </Typography>
                {/*<Typography variant="h3" sx={{pt: 5}} paragraph>*/}
                {/*    Artificial Intelligence for the Life Sciences*/}
                {/*</Typography>*/}
                {/*<Typography*/}
                {/*    sx={{my: 5}}*/}
                {/*    variant="h4"*/}
                {/*    paragraph>*/}
                {/*    All you need to know to get started with AI in the life sciences.*/}
                {/*</Typography>*/}
            </Grid>
            <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={0} md={6}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} sx={{pb: 2}}>
                    <img src={'/assets/AI-Chem.png'} alt={'ails'} height={'300px'}/>
                </Stack>
            </Grid>
        </Grid>
        <Container maxWidth="xl"
                   sx={{px: {xs: 1, md: 3}, pt: {xs: 5}, pb: 3}}>
            <Grid container
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  spacing={2}>
                <Grid item order={{xs: 2, md: 1}} xs={12} md={10}>
                    {matchesLG ? <TabsBox value={value}
                                          width={"800px"}
                                          handleChange={handleChange}
                                          a11yProps={a11yProps}/> : matchesMD ? <TabsBox value={value}
                                                                                         width={"550px"}
                                                                                         handleChange={handleChange}
                                                                                         a11yProps={a11yProps}/> : matchesSM ?
                        <TabsBox value={value}
                                 width={"400px"}
                                 handleChange={handleChange}
                                 a11yProps={a11yProps}/> : <TabsBox value={value}
                                                                    width={"350px"}
                                                                    handleChange={handleChange}
                                                                    a11yProps={a11yProps}/>}
                    <TabPanel value={value} index={0}>
                        <OverviewTabPanel/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ProgrammeTabPanel/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TeamTabPanel/>
                    </TabPanel>
                </Grid>
                <Grid item order={{xs: 1, md: 2}} xs={12} md={2}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CalendarTodayOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    January 2025
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <ViewTimelineOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    40h - 10 weeks
                                </Typography>
                                <Typography variant={"h6"}>
                                    (5 modules, 8h each)
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <LocationOnOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    Online
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <LocalOfferOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    From 600€
                                </Typography>
                                <Typography variant={"h6"}>
                                    (Early Bird)
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <TranslateOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    English
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemIcon>
                                <SendOutlined/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"h6"}>
                                    <Link href={"https://form.jotform.com/243512777201048"}>
                                        Pre-Register
                                    </Link>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    </>)
}